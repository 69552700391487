import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import HeadLabel from "../components/head-label"
import Seo from "../components/seo"
import Button from "../components/button"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../components/hero"

export default function Service() {
  return (
    <Layout>
      <Seo
        title="Service | 株式会社STARKS | スタークス"
        description="株式会社STARKSが展開するコンサルティング事業、エステサロン事業、美容商材卸事業について掲載しています。"
        ogpSiteName="Service | 株式会社STARKSコーポレートサイト"
      />

      <Hero message1={"サービス・事業"} />

      <div className="container mx-auto">
        <div className="max-w-4xl mx-auto mt-10" id="consul">
          <HeadLabel label={"コンサルティングサービス"} />
          <div className="px-3 pb-8 typography">
            <div className="my-4">
              業界問わず、BtoB、BtoCサービスそれぞれで事業立ち上げやIT/DX推進の支援を行っております。
            </div>
          </div>
          <div className="px-6 py-4 mb-20 bg-gray-100">
            <div className="">コンサルティングサービスの特徴</div>
            <ol className="list-decimal list-inside">
              <li className="my-4">
                エンジニア経験豊富なIT・Web領域のリテラシーの高いコンサルタントが在籍
              </li>
              <li className="my-4">
                小売店や飲食店、サロンなど店舗のデジタル化やDX推進・運用改善事例が多数
              </li>
              <li className="my-4">
                業界問わず、SaaSサービスやEC・サブスクリプションサービスのサービス改善経験が豊富
              </li>
              <li className="my-4">
                ロジカルシンキングとデータドリブンな支援を得意とするコンサルティングチーム
              </li>
            </ol>
          </div>

          <HeadLabel label={"エステサロン事業"} />
          <div className="mb-20" id="salon">
            <div className="px-3 pb-8 typography">
              <StaticImage
                src="../images/lavoute.png"
                alt="CEO"
                placeholder="blurred"
                layout="fullWidth"
                className="m-auto max-w-[250px]"
              />
              <div className="mt-10">
                {new Date().getFullYear()}
                年現在、名古屋と岐阜2店舗を運営している美肌専門店「Lavoute(ラボーテ)」。
              </div>
              <div>全ての女性が心からくつろげる空間を提供しています。</div>
              <div className="my-4">
                <div>
                  Lavouteでは、皮膚科医監修のもと『高い技術力』『高い美容学』を兼ね揃えたエステティシャンがお客様のお身体とお肌を第一に考え親身に寄り添い『綺麗になりたい』をサポート致します。
                </div>
                <div>
                  当店ではお客様のお悩みに合わせたトリートメントをご提案し、美しさを引き出せるよう施術させていただきます。
                </div>
              </div>
            </div>
            <Button
              label={"LaVouteサイト"}
              link={"https://lavoute.jp/"}
              external={true}
            />

            <div className="my-6">
              <div className="flex justify-around flex-wrap">
                <div className="w-full mb-5 md:mb-0 md:w-1/2 px-2 max-w-[300px]">
                  <StaticImage
                    src="../images/3dsalon.png"
                    alt="CEO"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="rounded-md"
                  />
                </div>
                <div className="md:w-1/2 px-2 self-center">
                  <div>
                    コロナ渦中で非対面で当店を知って頂く為、VRを導入しました。
                  </div>
                  <div>
                    バーチャル空間でサロンに入店していただくことができます。またご予約も可能です。
                  </div>
                  <Button
                    label={"VR空間を見る"}
                    link={"https://my.matterport.com/show/?m=asfjgcA5dym"}
                    external={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Contact />
    </Layout>
  )
}
